
















import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { Breakpoints } from "@/store/modules/layout";
import { mixins } from "vue-class-component";
import { namespace } from "vuex-class";

const layout = namespace("layout");

@Component
export default class KnowledgeBaseSearchBar extends mixins(
  TranslatableComponent
) {
  @Prop()
  private value!: string;

  private stored: string = "";

  @Watch("value", { immediate: true })
  syncStored() {
    this.stored = this.value;
  }

  @Emit("input")
  submit() {
    return this.stored;
  }

  reset() {
    this.stored = "";
    this.submit();
  }

  @layout.Getter
  public responsiveAtLeast!: (breakpoint: Breakpoints) => boolean;

  get computedSearchPlaceholder(): string {
    return this.responsiveAtLeast(Breakpoints.lg)
      ? `${this.translated_component_value("placeholder")}`
      : `${this.translated_component_value("placeholder_short")}`;
  }
}
