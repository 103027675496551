























































































































import { Component, Watch } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import KnowledgeBaseItem from "@/components/knowledgeBase/KnowledgeBaseItem.vue";
import {
  KnowledgeBaseArticle,
  KnowledgeBaseData,
  ModalFilters,
} from "@/interfaces/KnowledgeBase";
import Loader from "@/components/Loader.vue";

import magnoliaCmsApi, { KnowledgeBaseGetParams } from "@/api/magnoliaCmsApi";
import { AxiosResponse } from "axios";
import Pagination from "@/components/Pagination.vue";
import { Route } from "vue-router";
import SearchBar from "@/components/knowledgeBase/KnowledgeBaseSearchBar.vue";
import { Dictionary } from "vue-router/types/router";
import KnowledgeBaseFilterModal from "@/components/modals/KnowledgeBaseFilterModal.vue";
import KnowledgeBaseFilterList from "@/components/knowledgeBase/KnowledgeBaseFilterList.vue";
import RangeDatepicker from "@/components/knowledgeBase/RangeDatepicker.vue";
import { capitalizeFirstLetter } from "@/filters";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";

@Component({
  components: {
    RangeDatepicker,
    SearchBar,
    KnowledgeBaseFilterModal,
    KnowledgeBaseItem,
    KnowledgeBaseFilterList,
    Loader,
    Pagination,
    TheHeader,
    Page,
  },
})
export default class KnowledgeBase extends TranslatableComponent {
  private data: KnowledgeBaseData | null = null;
  private filters: {
    types: string[];
    disciplines: string[];
    branches: string[];
    fromDate?: number;
    toDate?: number;
  } | null = null;
  private isContentLoading: boolean = true;
  private filtersAreLoading: boolean = true;
  private q: string | undefined = "";
  private hasApiError: boolean = false;

  get disciplines() {
    return this.filters?.disciplines;
  }

  get types() {
    return this.filters?.types;
  }

  get branches() {
    return this.filters?.branches;
  }

  get searchedValue() {
    return this.data?.query || "";
  }

  get articles(): KnowledgeBaseArticle[] {
    if (this.data?.hits) {
      return this.data.hits;
    }

    return [];
  }

  mounted() {
    this.q = (this.$route?.query?.q as string) || "";
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(route: Route) {
    const knowledgeBaseGetParams: KnowledgeBaseGetParams = {};

    knowledgeBaseGetParams.q = route?.query.q
      ? (route.query.q as string)
      : undefined;

    knowledgeBaseGetParams.pageNumber = route?.query.p
      ? Number(route.query.p)
      : undefined;

    knowledgeBaseGetParams.disciplines = route?.query.disciplines
      ? this.getFilterValuesFromQuery(route.query, "disciplines")
      : undefined;

    knowledgeBaseGetParams.types = route?.query.types
      ? this.getFilterValuesFromQuery(route.query, "types")
      : undefined;

    knowledgeBaseGetParams.branches = route?.query.branches
      ? this.getFilterValuesFromQuery(route.query, "branches")
      : undefined;

    knowledgeBaseGetParams.fromDate = route?.query.fromDate
      ? Number(route.query.fromDate)
      : undefined;

    knowledgeBaseGetParams.toDate = route?.query.toDate
      ? Number(route.query.toDate)
      : undefined;

    this.getKnowledgeBaseData(knowledgeBaseGetParams);
  }

  getKnowledgeBaseData(knowledgeBaseGetParams: KnowledgeBaseGetParams) {
    this.isContentLoading = true;
    this.filtersAreLoading = !this.filters;

    magnoliaCmsApi
      .getArticles(knowledgeBaseGetParams)
      .then((response: AxiosResponse<KnowledgeBaseData>) => {
        if (!response.data) {
          this.hasApiError = true;
        }

        if (!this.filters) {
          this.filters = this.getAvailableFilters(response.data);
        }
        this.data = response.data || null;
      })
      .catch(() => {
        this.hasApiError = true;
        this.data = null;
        this.filters = null;
      })
      .finally(() => {
        this.isContentLoading = false;
        this.filtersAreLoading = false;
      });
  }

  getFilterValuesFromQuery(
    query: Dictionary<string | (string | null)[]>,
    filterName: "types" | "branches" | "disciplines"
  ): string[] {
    const value = (query[filterName] as string) || [];
    return typeof value === "string" ? value.split(",") : value;
  }

  getDateValuesFromQuery(
    query: Dictionary<string | (string | null)[]>,
    filterName: "fromDate" | "toDate"
  ): number | undefined {
    const value = (query[filterName] as string) || undefined;
    return typeof value === "string" ? Number(value) : value;
  }

  getAvailableFilters(data: KnowledgeBaseData): {
    types: string[];
    disciplines: string[];
    branches: string[];
  } {
    return {
      types: data?.aggregations?.unfiltered_types.buckets.map(
        (filter) => filter.key
      ),
      disciplines: data?.aggregations?.unfiltered_disciplines.buckets.map(
        (filter) => filter.key
      ),
      branches: data?.aggregations?.unfiltered_branches.buckets.map(
        (filter) => filter.key
      ),
    };
  }

  paginationClickHandler(pageNumber: number) {
    const query = {
      ...this.$route?.query,
      p: pageNumber.toString(),
    } as Dictionary<string>;

    this.$router.push({ query });
  }

  openFilterModal() {
    this.$modal.show("filter", {
      activeTypeFilters: this.getFilterValuesFromQuery(
        this.$route.query,
        "types"
      ),
      activeBranchFilters: this.getFilterValuesFromQuery(
        this.$route.query,
        "branches"
      ),
      fromDate: this.getDateValuesFromQuery(this.$route.query, "fromDate"),
      toDate: this.getDateValuesFromQuery(this.$route.query, "toDate"),
    });
  }

  @Watch("q")
  doSearch(q: string) {
    // Don't execute search if the search value is the same
    if (this.$route?.query.q === q) return;

    let query = { ...this.$route?.query } as Dictionary<string>;

    // Set page to 1
    delete query.p;

    if (!q) {
      // Delete q from url if present
      if (this.$route?.query?.q) {
        delete query.q;
        this.$router.push({ query });
      }

      return;
    }

    query.q = q;

    this.$router.push({ query });
  }

  doModalFilter(value: ModalFilters) {
    const query = { ...this.$route.query } as Dictionary<string>;

    if (value.types?.length) {
      query.types = value.types.join();
    } else {
      delete query.types;
    }

    if (value.branches?.length) {
      query.branches = value.branches.join();
    } else {
      delete query.branches;
    }

    if (value.fromDate) {
      query.fromDate = value.fromDate.getTime().toString();
    } else {
      delete query.fromDate;
    }

    if (value.toDate) {
      query.toDate = value.toDate.getTime().toString();
    } else {
      delete query.toDate;
    }

    if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
      // Set page to 1
      delete query.p;
      this.$router.push({ query });
    }
  }

  doDisciplineFilter(value: string) {
    let query = { ...this.$route?.query } as Dictionary<string>;
    const disciplines = this.getFilterValuesFromQuery(query, "disciplines");
    const disciplinesIndex = disciplines.indexOf(value);

    if (disciplinesIndex !== -1) {
      disciplines.splice(disciplinesIndex, 1);
    } else {
      disciplines.push(value);
    }

    if (disciplines?.length) {
      query.disciplines = disciplines.join();
    } else {
      delete query.disciplines;
    }

    this.$router.push({ query });
  }

  isDisciplineActive(value: string) {
    const disciplines = this.getFilterValuesFromQuery(
      this.$route?.query,
      "disciplines"
    );

    return disciplines.indexOf(value) !== -1;
  }

  getFilterLabel(filterOption: string): string {
    if (this.hasTranslation(`views.knowledge_base.filters.${filterOption}`)) {
      return this.translated_raw_view_value(
        `knowledge_base.filters.${filterOption}`
      ) as string;
    } else {
      return capitalizeFirstLetter(filterOption);
    }
  }
}
