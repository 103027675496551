

























































import { Component, Prop, Ref } from "vue-property-decorator";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import BasicModal from "@/components/modals/partials/BasicModal.vue";
import KnowledgeBaseFilterList from "@/components/knowledgeBase/KnowledgeBaseFilterList.vue";

import { ModalFilters } from "@/interfaces/KnowledgeBase";
import RangeDatepicker from "@/components/knowledgeBase/RangeDatepicker.vue";

@Component({
  components: {
    BasicModal,
    KnowledgeBaseFilterList,
    RangeDatepicker,
  },
})
@Translatable("modals")
export default class KnowledgeBaseFilterModal extends TranslatableComponent {
  @Ref() readonly rangeDatepicker!: typeof RangeDatepicker;

  @Prop() private componentName!: string;
  @Prop() private types!: string[];
  @Prop() private branches!: string[];

  private activeFilters: ModalFilters = {
    types: [],
    branches: [],
    fromDate: undefined,
    toDate: undefined,
  };

  beforeOpen(data: {
    params: {
      activeTypeFilters: string[];
      activeBranchFilters: string[];
      fromDate: number | undefined;
      toDate: number | undefined;
    };
  }) {
    this.activeFilters.types = data.params.activeTypeFilters;
    this.activeFilters.branches = data.params.activeBranchFilters;
    this.activeFilters.fromDate =
      typeof data.params.fromDate === "number"
        ? new Date(data.params.fromDate)
        : undefined;
    this.activeFilters.toDate =
      typeof data.params.toDate === "number"
        ? new Date(data.params.toDate)
        : undefined;
  }

  resetFilter() {
    this.activeFilters.types = [];
    this.activeFilters.branches = [];
    this.activeFilters.fromDate = undefined;
    this.activeFilters.toDate = undefined;

    // @ts-ignore
    this.rangeDatepicker?.reset();
  }

  handleFilterClick() {
    this.$emit("do-modal-filter", this.activeFilters);
    this.hideModal();
  }

  cancelFilter() {
    this.hideModal();
  }

  hideModal() {
    this.$modal.hide(this.componentName);
  }
}
