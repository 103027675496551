




















import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import DjlDatepicker from "@/components/form-components/djlDatepicker.vue";

@Component({ components: { DjlDatepicker } })
export default class RangeDatepicker extends mixins(TranslatableComponent) {
  @Ref() readonly fromDatepicker!: typeof DjlDatepicker;
  @Ref() readonly toDatepicker!: typeof DjlDatepicker;

  @Prop({
    default: () => {
      return { from: "", to: "" };
    },
  })
  private labels!: { from: string; to: string };

  @Prop()
  private fromDate!: string | number;

  @Prop()
  private toDate!: string | number;

  @PropSync("fromDate")
  syncedFromDate!: number;

  @PropSync("toDate")
  syncedToDate!: number;

  @Watch("fromDate")
  refreshToDatePicker() {
    // @ts-ignore
    this.toDatepicker?.refresh();
  }

  @Watch("toDate")
  refreshFromDatePicker() {
    // @ts-ignore
    this.fromDatepicker?.refresh();
  }

  reset() {
    // @ts-ignore
    this.toDatepicker?.refresh();
    // @ts-ignore
    this.fromDatepicker?.refresh();
  }
}
