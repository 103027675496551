var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination"},[_c('a',{staticClass:"pagination__previous",class:{
      'pagination__previous--disabled': _vm.currentPage === 1,
    },on:{"click":function($event){return _vm.paginationClickHandler(_vm.currentPage - 1, $event)}}},[_c('i',{staticClass:"icon--icon-arrow-right"}),_vm._v(" "+_vm._s(_vm.translated_component_value("button.previous"))+" ")]),_vm._l((_vm.numberOfPages),function(pageNumber){return _c('a',{key:pageNumber,class:[
      !_vm.isShowPageNumber(pageNumber, _vm.currentPage) &&
        'pagination__page--hidden',
      _vm.isCurrentPage(pageNumber, _vm.currentPage) && 'pagination__page--active',
      _vm.isVisibleOnMobile(pageNumber, _vm.currentPage) &&
        'pagination__page--show-always',
      'pagination__page' ],on:{"click":function($event){return _vm.paginationClickHandler(pageNumber, $event)}}},[_vm._v(" "+_vm._s(pageNumber)+" ")])}),_c('a',{staticClass:"pagination__next",class:{
      'pagination__next--disabled': _vm.currentPage === _vm.numberOfPages,
    },on:{"click":function($event){return _vm.paginationClickHandler(_vm.currentPage + 1, $event)}}},[_vm._v(" "+_vm._s(_vm.translated_component_value("button.next"))+" "),_c('i',{staticClass:"icon--icon-arrow-right"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }