



















import { Component, Prop, PropSync } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import CheckboxField from "@/components/form-components/CheckboxField.vue";
import { capitalizeFirstLetter } from "@/filters";

@Component({ components: { CheckboxField } })
export default class KnowledgeBaseFilterList extends TranslatableComponent {
  @Prop()
  filters!: string[];

  @Prop()
  title!: string[];

  @Prop({ default: [] })
  activeFilters!: string[];

  @PropSync("activeFilters", { type: Array, default: () => [] })
  syncedActiveFilters!: string[];

  getFilterLabel(filterOption: string): string {
    if (this.hasTranslation(`views.knowledge_base.filters.${filterOption}`)) {
      return this.translated_raw_view_value(
        `knowledge_base.filters.${filterOption}`
      ) as string;
    } else {
      return capitalizeFirstLetter(filterOption);
    }
  }

  onChange(value: string) {
    this.syncedActiveFilters.includes(value)
      ? this.removeFilter(value)
      : this.syncedActiveFilters.push(value);
  }

  removeFilter(value: string) {
    this.syncedActiveFilters = Array.from(
      this.syncedActiveFilters.filter((filter) => filter !== value)
    );
  }
}
