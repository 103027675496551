










































import { Component, Prop } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";

@Component
export default class Pagination extends mixins(TranslatableComponent) {
  @Prop()
  private numberOfPages!: number;

  @Prop()
  private currentPage!: number;

  readonly numberOfButtonsOnEachSide: number = 3;
  readonly numberOfPaginationButtons: number =
    this.numberOfButtonsOnEachSide * 2 + 1;

  isShowPageNumber(pageNumber: number, currentPage: number): boolean {
    if (currentPage <= this.numberOfButtonsOnEachSide) {
      // Render beginning part of the pagination
      return pageNumber <= this.numberOfPaginationButtons;
    }

    if (currentPage > this.numberOfPages - this.numberOfButtonsOnEachSide) {
      // Render last part of the pagination
      return pageNumber > this.numberOfPages - this.numberOfPaginationButtons;
    }

    // Render middle part of the pagination
    return (
      pageNumber >= currentPage - this.numberOfButtonsOnEachSide &&
      pageNumber <= currentPage + this.numberOfButtonsOnEachSide
    );
  }

  isCurrentPage(pageNumber: number, currentPage: number): boolean {
    return pageNumber === currentPage;
  }

  isVisibleOnMobile(pageNumber: number, currentPage: number): boolean {
    if (currentPage === 1) {
      return pageNumber == 2 || pageNumber == 3;
    }

    if (currentPage === this.numberOfPages) {
      return (
        pageNumber == this.numberOfPages - 1 ||
        pageNumber == this.numberOfPages - 2
      );
    }

    return pageNumber == currentPage - 1 || pageNumber == currentPage + 1;
  }

  paginationClickHandler(pageNumber: number, event: Event) {
    const classList = (event.currentTarget as HTMLInputElement).classList;

    if (
      classList.contains("pagination__page--active") ||
      classList.contains("pagination__previous--disabled") ||
      classList.contains("pagination__next--disabled")
    ) {
      return;
    }

    this.$emit("pagination-click", pageNumber);
  }
}
